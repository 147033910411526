import React from 'react';

import Title from '../../__atoms/title';
import Button from '../../__atoms/button';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule SectionHeader
 *
 * The header of a section
 */
function SectionHeader(props) {
  const {
    className = '',
    title = '',
    description = null,
    linkData = null,
    image = null,
    variant = 4,
    level = 2,
    spacing = 4,
    centerTitle = false,
    ...other
  } = props;

  return (
    <>
      <div className={`${css['molecule__section-header-container']} ${className}`} {...other} data-spacing={spacing}>
        {image && (
          <div className={css['image-container']}>
            <img src={image?.src} alt={`${image?.alt}`} className={css['image']} />
          </div>
        )}
        <div className={css['head-container']} data-center-title={centerTitle}>
          <Title level={level} variant={variant}>
            {title}
          </Title>
          {linkData && (
            <>
              <Button
                color={linkData?.color || 'light'}
                link={linkData?.link}
                size={linkData?.size}
                variant={linkData?.variant}
              >
                {linkData?.title}
              </Button>
            </>
          )}
        </div>
        {description && (
          <p className={css['description']} data-text-color={description?.textColor}>
            {description?.value}
          </p>
        )}
      </div>
    </>
  );
}

export default SectionHeader;
