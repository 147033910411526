import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoWindowF, MarkerF, useLoadScript } from '@react-google-maps/api';
import InfoDealer from '../info-dealer';
import Slider from 'react-slick';
import getConfig from 'next/config';
import { useMediaQuery } from 'react-responsive';

import css from './styles.module.scss';
import 'slick-carousel/slick/slick.css';

const { publicRuntimeConfig } = getConfig();

interface CustomSlider extends Slider {
  slickGoTo: (index: number) => void;
}

function MapInformation(props) {

  const google = typeof window === 'object' ? window.google : null;

  const {
    className = '',
    selectedCity,
    selectedState,
    selectedMapCenter,
    markers,
    locale,
    selectedZoom = 8,
    centerOffset = 0,
    ...other
  } = props;

  const sliderRef = useRef<CustomSlider | null>(null);
  const containerEl = React.useRef(null);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isTDB = locale?.toLowerCase() === 'pt-br';
  const zoomForSelectedMark = 15;
  const insignificantNumForDiff = 0.00001;
  const googleKey = publicRuntimeConfig?.G_MAPS_KEY;
  const countryCenter = isTDB
    ? { lat: -15.665227002446661, lng: -47.992979546301854 }
    : { lat: -34.60548110292948, lng: -58.39672471921689 };

  const sliderSettings = {
    speed: 500,
    useTransform: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    padding: 0
  };

  const [activeMarkerIndex, setActiveMarkerIndex] = useState<number | null>(null);
  const [isShowWindowAndCard, setIsShowWindowAndCard] = useState(false);
  const [allMarkersLoaded, setAllMarkersLoaded] = useState(false);
  const [isInfoWindowOptionsActive, setIsInfoWindowOptionsActive] = useState(true);
  const [mapObj, setMapObj] = useState<google.maps.Map | null>(null);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [mapCenter, setMapCenter] = useState(countryCenter);
  const [mapZoom, setMapZoom] = useState(1);

  if (!googleKey) {
    console.error('Google token is not set');
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleKey
  });

  useEffect(() => {
    if (isLoaded && markers?.length > 0) {
      setFitBounds();
      setAllMarkersLoaded(true);
    }
  }, []);

  useEffect(() => {
    let boundsChangedListener;
    let newBounds;

    const handleBoundsChanged = () => {
      const currentZoom = mapObj.getZoom() || 0;
      updateMapZoom(currentZoom > zoomForSelectedMark ? zoomForSelectedMark : currentZoom);
      setBounds(newBounds);
      if (boundsChangedListener) {
        google.maps.event.removeListener(boundsChangedListener);
      }
    };

    if (mapObj && markers?.length) {
      newBounds = new google.maps.LatLngBounds();
      markers.forEach((mark) => newBounds.extend(new google.maps.LatLng(mark.position.lat, mark.position.lng)));

      boundsChangedListener = google.maps.event.addListener(mapObj, 'bounds_changed', handleBoundsChanged);
      mapObj.fitBounds(newBounds);
    }

    return () => {
      if (boundsChangedListener) {
        google.maps.event.removeListener(boundsChangedListener);
      }
    };
  }, [mapObj, markers]);

  useEffect(() => setFitBounds(), [markers]);
  useEffect(() => {
    if (selectedMapCenter) {
      const index = markers?.findIndex(
        ({ position }) => position.lat === selectedMapCenter.lat && position.lng === selectedMapCenter.lng
      );

      if (index != -1) {
        setActiveMarkerIndex(index);
        adjustMapView(markers[index]);
        sliderRef?.current?.slickGoTo(index);
      }
    }
  }, [selectedMapCenter]);

  useEffect(() => {
    if (selectedZoom) {
      updateMapZoom(selectedZoom);
    }
  }, [selectedZoom]);

  useEffect(() => {
    if (selectedState || selectedCity) {
      setActiveMarkerIndex(0);
    }

  }, [selectedCity, selectedState]);

  const handleActiveMarker = (index) => {
    setIsInfoWindowOptionsActive(false);
    setIsShowWindowAndCard(true);
    setActiveMarkerIndex(index);
    sliderRef?.current?.slickGoTo(index);
  };

  const onInfoWindowClose = () => {
    if (activeMarkerIndex !== null) {
      adjustMapView(markers[activeMarkerIndex]);
    }
  };

  const setFitBounds = () => {
    if (allMarkersLoaded && markers?.length && google?.maps && mapObj) {
      const newBounds = new google.maps.LatLngBounds();
      markers?.forEach((mark) => newBounds.extend(new google.maps.LatLng(mark.position.lat, mark.position.lng)));

      if (JSON.stringify(newBounds) !== JSON.stringify(bounds)) {
        setBounds(newBounds);
        mapObj.fitBounds(newBounds);
        const currentZoom = mapObj.getZoom() || 0;
        updateMapZoom(currentZoom > zoomForSelectedMark ? zoomForSelectedMark : currentZoom);
      }
    }
  };

  const onClearActiveMarker = () => {
    setActiveMarkerIndex(null);
  };

  const handleOnLoad = (map: google.maps.Map) => {
    setMapObj(map);
  };

  const beforeChange = (oldIndex, newIndex) => {
    setIsInfoWindowOptionsActive(false);
    setActiveMarkerIndex(newIndex);
    adjustMapView(markers[newIndex]);
  };

  const adjustMapView = (selectedMark) => {
    if (selectedMark?.position) {
      updateMapZoom(zoomForSelectedMark);
      updateMapCenter({ ...selectedMark.position });
    }
  };

  const updateMapZoom = (newMapZoom) => {
    if (mapZoom === newMapZoom) {
      newMapZoom += insignificantNumForDiff;
    }

    setMapZoom(newMapZoom);
  };

  const updateMapCenter = (newCenterPosition) => {
    newCenterPosition.lng += isTabletOrMobile ? 0 : centerOffset;

    if (mapCenter.lng === newCenterPosition.lng) {
      newCenterPosition.lng += insignificantNumForDiff;
    }

    setMapCenter(newCenterPosition);
  };

  useEffect(() => {
    if (selectedCity) {
      const index = markers?.findIndex((marker) => {
        return  marker.address?.city === selectedCity;
      });

      if (index !== -1) {
        setActiveMarkerIndex(index);
        adjustMapView(markers[index]);
        sliderRef?.current?.slickGoTo(index);
      }
    }
  }, [selectedCity]);

  useEffect(() => {

    if(selectedState !== 'placeholder' && selectedState !== null && selectedState !== undefined) {
      setIsShowWindowAndCard(true);
    }
  }, [selectedState]);

  return (
    <div className={`${css['molecule__map-information-container']} ${className}`} {...other}>
      <div className={css['container-google']} ref={containerEl}>
        {isLoaded ? (
          <GoogleMap
            center={mapCenter}
            zoom={mapZoom}
            onLoad={handleOnLoad}
            onClick={onClearActiveMarker}
            mapContainerStyle={{ width: '100%', height: '100%' }}
          >
            {markers?.length > 0 &&
              markers?.map((marker, index) => {
                return (
                  <>
                    <MarkerF
                      key={`marker-${index}`}
                      position={marker?.position}
                      onClick={() => handleActiveMarker(index)}
                    />
                    { activeMarkerIndex === index && isShowWindowAndCard && (
                      <InfoWindowF
                        options={
                          isInfoWindowOptionsActive &&
                          selectedCity && { pixelOffset: new window.google.maps.Size(0, -40) }
                        }
                        position={{ lat: marker.position.lat + 0.002, lng: marker.position.lng }}
                        onCloseClick={() => {
                          onClearActiveMarker();
                          onInfoWindowClose();
                        }}
                      >
                        <div>{marker.name}</div>
                      </InfoWindowF>
                    )}
                  </>

                );
              })}
          </GoogleMap>
        ) : null}
      </div>

      { isShowWindowAndCard && (selectedCity || activeMarkerIndex !== null) && (
        <div className={css['carousel-info']}>
          <Slider
            ref={sliderRef}
            settings={sliderSettings}
            beforeChange={beforeChange}
            className={`${css['action-banner-wrapper']} ${className}`}
            {...other}
          >
            {markers?.map((mark, key) => (
              <div id={mark.id} key={key} className={css['info-slide-container']}>
                <InfoDealer
                  className={css['info']}
                  dealer={mark}
                  locale={locale}
                  handleInfoDealer={() => adjustMapView(mark)}
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
}

export default MapInformation;
