import React from 'react';

import Wrapper from '../__atoms/wrapper';
import Section from '../__atoms/section';
import CardsList from '../__molecules/cards-list';
import SectionHeader from '../__molecules/section-header';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SectionCard
 *
 * A section that will render cards.
 */
function SectionCard(props) {

  const {
    className = '',
    title = null,
    description = null,
    callToAction = null,
    spacingTop = 0,
    spacingBottom = 0,
    cardsData,
    buttonColor,
    settings = {},
    ...other
  } = props;

  const { bgColor = 'default', centerContent = false } = settings;

  return (
    <Wrapper
      className={`${css['organism__section-card-container']} ${className}`}
      bgColor={bgColor}
      data-spacing-top={spacingTop}
      data-spacing-bottom={spacingBottom}
      data-center-content={centerContent}
      {...other}
    >
      <Section>
        {settings.showTitle && title && (
          <SectionHeader
            title={title}
            description={{
              value: description
            }}
            level={2}
            variant="2"
            linkData={
              callToAction
                ? {
                    title: callToAction.label,
                    link: callToAction.target,
                    color: buttonColor || 'ghost-2'
                  }
                : null
            }
          />
        )}
        {cardsData?.length > 0 && <CardsList cardsData={cardsData} {...settings} />}
      </Section>
    </Wrapper>
  );
}

export default SectionCard;
