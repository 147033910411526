/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Logger, genericLocator, buildTemplateLocator, buildBlockBuilder } from '@on2-dev/on2-frontend-common/index.cjs';
import { TemplateDependency } from '@on2-dev/on2-frontend-common/@types/templates';
import * as TService_Templates from 'templates/_index';
import * as TService_Blocks from 'components/_metas';
import { MissingBlock } from 'components/_index';
import * as General_Blocks from '@on2-dev/tyt-general-resources/metas';

const INTERNAL_TEMPLATES = {
  TService: TService_Templates
};

const INTERNAL_BLOCKS = {
  TService: TService_Blocks,
  General: General_Blocks
};

export type RepoType = {
  get: CallableFunction,
  post: CallableFunction
}

export type PageBuilderDependency = TemplateDependency;

type PageBuilderExtraOptions = {
  repo: RepoType,
  dependencies?: PageBuilderDependency[]
}

class PageBuilder {

  protected readonly pageData: any;

  protected templates: any;

  protected blocks: any;

  protected options: PageBuilderExtraOptions;

  constructor(pageData: any, options: PageBuilderExtraOptions) {
    this.pageData = pageData;
    this.templates = INTERNAL_TEMPLATES;
    this.blocks = INTERNAL_BLOCKS;
    this.options = options;
  }

  public setTemplates(templates: any) {
    this.templates = templates;
    return this;
  }

  public setBlocks(blocks: any) {
    this.blocks = blocks;
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async page(context: any): Promise<any[]> {
    const templateName = this.pageData?.template || 'PageTemplate';
    const templateLocator = buildTemplateLocator(this.templates, 'TService');
    const blockBuilder = buildBlockBuilder(this.blocks, MissingBlock, 'TService');
    const templateConstructor = templateLocator(templateName);
    const template = new templateConstructor(this.blocks, blockBuilder);

    const dataFetcher = async (query: string, variables = {}) => {
      return await this.options.repo.get(query, variables);
    };

    let data = [];

    try {
      data = await this.options.repo.get(template.queryBuilder(), { pageId: this.pageData.id });
    } catch (error) {
      Logger.error('[PageBuilder] Failed to query page data. Reason: ', error);
      throw error;
    }

    context.page = this.pageData;
    template.dependencies?.each((dependencyName: string) => {
      if (!this.options.dependencies?.[dependencyName]) {
        throw new Error(`[PageBuilder] Required Template dependency was not defined in options. Dependency: ${dependencyName}`);
      }

      context[dependencyName] = this.options.dependencies[dependencyName];
    });

    return await template.build(data, context, dataFetcher);
  }
}

/**
 * Construct a builder to Instantiate the React Component
 *
 * @param name The class name of wanted Component
 * @param props Properties for component
 */
const buildComponentBuilder = (components: any) => {
  const buildComponent = (name: string, key: string, props: any, children?: []) => {
    let CustomComponent: any = MissingBlock;

    try {
      CustomComponent = genericLocator(name, components, 'Kinto');
      if (!CustomComponent) {
        Logger.debug(`[ComponentBuilder] Component with the name '${name}' not exists. Continuing with a empty placeholder.`);
        CustomComponent = MissingBlock;
      }
    } catch (err) {
      CustomComponent = MissingBlock;
    }

    const anchor = props?.anchor || key;

    if (props.anchor) {
      props = { ...props, style: { ...props.style, scrollMarginTop: '8rem' } };
    }

    if (!children || !children?.length) {
      return <CustomComponent id={anchor} key={key} { ...props } />;
    }

    const builded = [];

    children.forEach((tuple: any, idx: number) => {
      builded.push(buildComponent(tuple?.component, `${key}-children-${idx}`, tuple?.props, tuple?.children || []));
    });

    return <CustomComponent id={anchor} key={key} { ...props }>{builded}</CustomComponent>;
  };

  return buildComponent;
};

export { PageBuilder, buildComponentBuilder };
