import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom FormRadioGroup
 *
 * <!-- TODO: add a description here! -->
 */
function FormRadioGroup(props) {
  const {
    className = "",
    id,
    name,
    disabled,
    label,
    dataRegister,
    dataErrors,
    vertical = false,
    displayLabel = true,
    displayError = true,
    children,
    ...other
  } = props;

  function updatedChildren(children) {
    return React.Children.map(children, (child, i) => {
      const childDisabled = child?.props?.disabled ? true : disabled;
      return React.cloneElement(child, {
        ...child?.props,
        name: name,
        dataRegister: dataRegister,
        key: i,
        disabled: childDisabled,
      });
    });
  }

  return <div
    className={`${css["atom__form-radio-group-container"]} ${css["ff-container"]} ${className}`}
    data-testid="radio-group"
  >
    <label
      htmlFor={id}
      className={`${css["ff-label"]} ${disabled ? css["ff-disabled"] : ""} ${displayLabel ? '' : css["absent"]}`}
    >
      {label}
    </label>
    <div className={`${css["ff-radio-container"]} ${vertical ? css["ff-radio-column"] : ""}`}>
      {updatedChildren(children)}
    </div>
    {displayError && dataErrors?.type && (
      <span className={css[displayError && 'ff-error-message']}>
        {dataErrors?.message}
      </span>
    )}
  </div>;
}

export default FormRadioGroup;
