const getAllDealers = () => {
  return `
    query delears ($productId: String!) {
      dealers (query: { productId: $productId }) {
        id
        name
        dn
        addressId
        doc
        companyName
        idDealerTasa
        armored
        branch
        casaCentral
        contactEmail
        salesEmail
        afterSalesEmail
        phone
        whatsapp
        dlnMatriz
        preProdDomain
        urlToyota
        site
        welcome
        showRoomWeekDaysHour
        hfServicesSaturdayHour
        hfServicesWeekDaysHour
        hfShowRoomSaturdayHour
        hfShowRoomSundayHour

        dms {
          id
          name
        }

        services {
          id
          title
          subtitle
          content {

            icon {
              url
              title
            }
          }
        }

        address {
          city
          state
          neighborhood
          complement
          coordLat
          coordLng
          description
          number
          street
          uf
          zipcode
        }
      }
    }`;
};

const getAllDealersEssential = () => {
  return `query delears ($locale: String!) {
      dealers (locale: $locale) {
      name
      dn
      dms{
        name
      }
      id
      addressId
      doc
      companyName
      armored
      branch
      casaCentral
      contactEmail
      salesEmail
      afterSalesEmail
      phone
      whatsapp
      dlnMatriz
      preProdDomain
      urlToyota
      welcome
      address {
        city
        state
        neighborhood
        complement
        coordLat
        coordLng
        description
        number
        street
        uf
        zipcode
      }
    }
  }`;
};


const getAllDealersForForms = () => {
  return `query dealers ($productId: String!) {
    dealers (query: { productId: $productId }) {
      name
      dn
      id
      address {
        city
        state
        uf
      }
    }
  }`;
};

const findByFilter = () => {
  return `query delears ($locale: String!, $filter: FindDealersInput) {
      dealers (locale: $locale, query: $filter) {
      name
      id
      addressId
      doc
      companyName
      armored
      branch
      casaCentral
      contactEmail
      dn
      salesEmail
      afterSalesEmail
      phone
      whatsapp
      dlnMatriz
      preProdDomain
      urlToyota
      welcome
      services{
        id
        title
        subtitle
      }
      address {
        city
        state
        neighborhood
        complement
        coordLat
        coordLng
        description
        number
        street
        uf
        zipcode
      }
    }
  }`;
};

const Dealer = {
  getAllDealers,
  getAllDealersEssential,
  getAllDealersForForms,
  findByFilter
};

export default Dealer;
