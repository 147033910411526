export const captchaToken = async (action: string, key: string) => {
  if (typeof window === 'undefined') {
    return false;
  }

  if (typeof grecaptcha === 'undefined') {
    throw Error('Google Recaptcha is not available');
  }

  try {
    const token = await grecaptcha.enterprise?.execute(key, {
      action: action
    });

    return {
      token,
      action: action
    };
  } catch (err) {
    console.error('Failed to generate Google Recaptcha token', err);

    throw err;
  }
};
