import { anchor, bgColor,   spacingTop, spacingBottom, } from 'utils/meta';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import Dealer from 'utils/repo/dealers';

class FindDealers implements ContentBlock {
  label = 'Dealers list';

  component = 'FindDealers';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    spacingTop,
    spacingBottom,
    bgColor
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {

    const productId = extra?.context?.productId;
    const locale = extra?.context?.locale;

    const dealersByLocation = (await dataFetcher(Dealer.getAllDealers(), { productId: productId }))?.data
      ?.dealers;

    if (!dealersByLocation) {
      throw new Error('No dealers were found');
    }

    const dealers = [];

    dealersByLocation.forEach((item) => {
      if (item?.address?.coordLat && item?.address?.coordLng) {
        dealers.push({
          position: {
            lat: parseFloat(item.address.coordLat),
            lng: item.address.coordLng
          },
          ...item
        });
      }
    });

    const listStates = [...new Set(dealers.map((state) => state.address.state))].sort();

    return {
      dealers: dealers,
      locale: locale || 'pt-BR',
      listStates: listStates,
      spacingTop: extra?.settings?.spacingTop,
      spacingBottom: extra?.settings?.spacingBottom,
      bgColor: extra?.settings?.bgColor
    };
  }
}

export { FindDealers };
