import React from 'react';
import css from './styles.module.scss';

/**
 * Atom FormRadioButton
 *
 * <!-- TODO: add a description here! -->
 */
function FormRadioButton(props) {

  const {
    className = '',
    name,
    id,
    disabled,
    label,
    dataRegister,
    dataErrors,
    displayError = true,
    color,
    variant,
    children,
    ...other
  } = props;

  return (
    <div className={`${css['atom__form-radio-button-container']} ${className}`} data-variant={variant}>
      <label
        htmlFor={id}
        className={`${css['ff-label']} ${displayError ? '' : css['ff-label-clean']} ${
          disabled ? css['ff-disabled'] : ''
        }`}>
        <input
          name={name}
          id={id}
          type="radio"
          className={`${css['field-input']} ${dataErrors?.type ? css['ff-box-error'] : ''}`}
          {...dataRegister}
          {...other}
          disabled={disabled}></input>

        <span
          style={color ? { backgroundColor: color } : undefined}
          data-has-color={color ? color : undefined}
          className={`${css['checkmark']}`}></span>

        {label ? label : children}
      </label>

      {displayError && dataErrors?.type && (
        <span className={css['ff-error-message']}> {dataErrors.message} </span>
      )}
    </div>
  );
}

export default FormRadioButton;
