import React, { useState, useEffect } from 'react';

import Title from 'components/__atoms/title';
import FormSelect from 'components/__atoms/form-select';
import Wrapper from 'components/__atoms/wrapper';
import Section from 'components/__atoms/section';

import { useForm } from 'react-hook-form';
import MapInformation from 'components/__molecules/map-information';
import { updateURLParameter } from 'utils/forms';
import i18n from 'utils/i18n';
import css from './styles.module.scss';

function FindDealers(props) {
  const t = i18n.useTranslations('components.find-dealers');

  const {
    className = '',
    dealers = [],
    listStates,
    spacingTop = 6,
    spacingBottom=6,
    locale,
    bgColor = 'gray',
    ...other
  } = props;

  const { register, setValue, watch } = useForm({
    defaultValues: {
      state: 'placeholder',
      city: 'placeholder'
    }
  });

  const [markers, setMarkers] = useState([]);

  const mapZoom = 1;

  const [citiesFromStates, updateCitiesFromStates] = useState([]);

  const watchCity = watch('city');
  const watchState = watch('state');

  useEffect(() => {
    const url = new URL(location.href);
    const params = url.searchParams;

    const state = params.get('state');
    const city = params.get('city');

    if (state !== null && state !== '') {
      setValue('state', state);

      if(city !== null && city !== ''){
        setValue('city', city);
      } else {
        const dealersFromState = dealers?.filter(dealer => dealer?.address?.state.toUpperCase() === state.toUpperCase());
        setMarkers(dealersFromState);
      }

    } else {
      setMarkers(dealers);
    }
  }, []);


  useEffect(() => {
    if (watchState && watchState !== 'placeholder') {
      updateURLParameter('state', watchState);
      loadCitiesList();
    }
  }, [watchState]);

  useEffect(() => {
    if (watchCity && watchCity !== 'placeholder') {
     updateURLParameter('city', watchCity);
     const dealersFromCity = dealers?.filter(
      (dealer) => dealer?.address?.state.toUpperCase() === watchState?.toUpperCase() && dealer?.address?.city === watchCity
    );
    setMarkers(dealersFromCity);
    } else {
      updateURLParameter('city', null);
    }
  }, [watchCity]);


  function loadCitiesList() {
    const dealersFromState = dealers?.filter(
      (dealer) => dealer?.address?.state.toUpperCase() === watchState?.toUpperCase()
    );

    if (watchState !== 'placeholder') {
      setMarkers(dealersFromState);
    }

    const uniqueCitiesList = Array.from(
      new Set(dealersFromState.map((dealer) => dealer.address.city.toUpperCase()))
    ).sort();

    updateCitiesFromStates(uniqueCitiesList);
  }

  function onChangeState(event) {
    setValue('city', 'placeholder');
    updateURLParameter('city', null);
    setValue('state', event.target.value);
  }

  function onChangeCity(event) {
    setValue('city',  event.target.value );
  }

  return (
    <Wrapper bgColor={bgColor} spacingTop={spacingTop} spacingBottom={spacingBottom} className={`${css['find-dealers-container']} ${className}`} {...other}>
      <Section tag="div" className={css['form-container']}>

          <Title level={1} variant={2}>
            {t('title_dealership')}
          </Title>
          <div className={css['filter-form']}>
            <FormSelect
              displayLabel={false}
              displayError={false}
              id="state"
              placeholder={t('placeholder_state')}
              className={css['select-input']}
              dataRegister={register('state')}
              onChange={onChangeState}
            >
              {listStates?.length > 0 ? (
                listStates?.map((state, index) => {
                  return (
                    <option key={index} value={state.toUpperCase()}>
                      {state.toUpperCase()}
                    </option>
                  );
                })
              ) : (
                <option>{t('placeholder_state')}</option>
              )}
            </FormSelect>
            <FormSelect
              displayLabel={false}
              displayError={false}
              id="city"
              placeholder={t('placeholder_city')}
              className={css['select-input']}
              dataRegister={register('city')}
              onChange={onChangeCity}
            >
              {citiesFromStates.length > 0 ? (
                citiesFromStates?.map((city, index) => {
                  return (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  );
                })
              ) : (
                <option>{t('placeholder_city')}</option>
              )}
            </FormSelect>
          </div>
      </Section>

      <MapInformation
        data-id={'map'}
        locale={locale}
        markers={markers}
        selectedCity={watchCity}
        selectedState={watchState}
        selectedZoom={mapZoom}
        centerOffset={-0.012}
      />
    </Wrapper>
  );
}

export default FindDealers;
