import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

import Media from '../../__molecules/media';
import AnchorLink from '../../__atoms/anchor-link';
import { verifyIfLinkIsInAnotherDomain } from 'utils/url';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule Card
 *
 * A card component
 */
function Card(props) {
  const {
    className = '',
    title,
    content,
    additionalContent,
    linkLabel,
    link,
    links,
    isHorizontal = false,
    isExternal = false,
    isDownload = false,
    bgColor = null,
    color,
    variant,
    media,
    ...other
  } = props;

  const [dataHidden, setDataHidden] = useState(false);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const contentRef = useRef(null);

  const t = i18n.useTranslations('components.card');

  const isExternalLink = link ? verifyIfLinkIsInAnotherDomain(link) : false;
  const mainLink = link  || (links && links.length === 1 && links[0].link);

  function onDataHidden() {
    setDataHidden(!dataHidden);
  }

  useEffect(() => {
    if (!isHorizontal) {
      document.fonts?.ready.then(function () {
        const ref = contentRef.current;

        if (ref?.scrollHeight > ref?.offsetHeight) {
          setIsContentOverflowing(true);
        }
      });
    }
  }, []);

  return (
    <div
      className={`${css['molecule__card-container']} ${className}`}
      {...other}
      data-is-horizontal={isHorizontal ? true : false}
      data-color={color}
      data-variant={variant}
      data-bg-color={bgColor}
    >
      {media && (
        <div className={css['card__media']}>
          { mainLink ? (
            <a href={mainLink} className={css['card__media-link']} target={isExternalLink ? '_blank' : null}>
              <Media url={media?.url} alt={media?.alt} type={media?.type} />
            </a>
          ) : (
            <Media url={media?.url} alt={media?.alt} type={media?.type} />
          )}
        </div>
      )}
      <div className={css['card__content-wrapper']}>
        <div className={css['card__content-container']}>
          {title ? (
            mainLink ? (
              <h3 className={css['card__title']}>
                <a href={mainLink} target={isExternalLink ? '_blank' : null}>
                  {title}
                </a>
              </h3>
            ) : (
              <h3 className={css['card__title']}>{title}</h3>
            )
          ) : null}
          {content && (
            <div data-hidden={dataHidden} className={css['card__content']} ref={contentRef}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
            </div>
          )}
          {isContentOverflowing && (
            <AnchorLink
              className={css['button-view-more']}
              linkLabel={dataHidden ? t('link_label_view_less') : t('link_label_view_more')}
              onClick={onDataHidden}
              onBlur={() => setDataHidden(false)}
            />
          )}
          {additionalContent && <p className={css['card__additional-content']}>{additionalContent}</p>}
        </div>
        {(link && linkLabel) && (
          <AnchorLink
            link={link}
            linkLabel={linkLabel}
            isExternal={isExternal}
            isDownload={isDownload}
            className={css['card__anchor-link']}
          />
        )}
        {links &&
         links.map((data, index) => {
          if(data.link && data.linkLabel) {
            return (
              <AnchorLink
              key={index}
              link={data.link}
              linkLabel={data.linkLabel}
              isExternal={isExternal}
              isDownload={isDownload}
              className={css['card__anchor-link']}
              />
            );
      }
        })
        }
      </div>
    </div>
  );
}

export default Card;
