//Return an array with all the states that have dealers
export function getStatesFromDealers(dealers) {

  if (!dealers) {return false;}

  return [...new Set(dealers.map((state) => state.address.state))].sort();
}

export function getDealersFromState(dealers, state) {

  if (!dealers && !state) {return false;}

  return dealers.filter((dealer) => {
    return dealer.address.state.toUpperCase() == state.toUpperCase();
  });
}

export function getCitiesThatHaveDealersFromState(state) {

  if (!state) {return false;}

  return [
    ...new Set(state.map((city) => city.address.city.toUpperCase()))
  ].sort();
}

export function isTextMask(value) {
  return value.replace(/\d/g, '');
}


export function removeSpecialCharacters(text) {
  var regex = /[^0-9a-zA-Z\s]/g;
  return text.replace(regex, '');
}


export const sendRequest = async (method, url, data) => {
  const methodsWithBody = [
    'POST',
    'PUT',
    'DELETE'
  ];
  const opts = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };

  if (methodsWithBody.includes(method)) {
    opts.body = JSON.stringify(data);
  }

  return await fetch(url, opts);
};

export function updateURLParameter(parameter, value) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if (value !== null) {
    params.set(parameter, value);
  } else {
    params.delete(parameter);
  }

  url.search = params.toString();

  const newUrl = url.pathname + url.search;
  window.history.pushState({ path: newUrl }, '', newUrl);
}
