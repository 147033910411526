import React, { useState, useRef } from 'react';

import ActionBannerItem from '../action-banner-item';
import Carousel from '../__molecules/carousel';
import Wrapper from '../__atoms/wrapper';

// loading the sass style fot the component
import css from './styles.module.scss';
/**
 * Molecule ActionBanner
 *
 * The main carousel banner
 */
function ActionBanner(props) {

  const {
    className = '',
    autoplay = false,
    sliderData,
    ...other
  } = props;

  const [isPlaying, setIsPlaying] = useState(autoplay);

  const carouselRef = useRef(null);

  const settings = {
    speed: 500,
    useTransform: false,
    arrows: false,
    dots: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    padding: 0,
    autoplay: autoplay,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    infinity: false,
    appendDots: (dots) => (
      <ul>
        {dots}
        <li>
          <button
            className={'slick-pause-button'}
            onClick={() => tooglePlayStatus()}
          >
            {isPlaying ? (
               <img src="/images/icons/icon-pause.svg" alt="Pause sliders" />
            ) : (
              <img src="/images/icons/icon-play.svg" alt="Play sliders" />
            )}
          </button>
        </li>
      </ul>
    ),
  };

  function tooglePlayStatus() {
    isPlaying
      ? carouselRef.current.slickPause()
      : carouselRef.current.slickPlay();
    setIsPlaying(!isPlaying);
  }

  return (
    <Wrapper {...other}>
      <div className={css['slider-wrapper']}>
        <Carousel
          settings={settings}
          carouselRef={carouselRef}
          className={`${css['action-banner-wrapper']} ${className}`}
        >
          {sliderData &&
            sliderData.map((slide, key) => {
              return (
                <ActionBannerItem
                  key={key}
                  title={slide?.title}
                  description={slide?.description}
                  logo={slide?.logo}
                  bannerImage={slide?.bannerImage}
                  cta={slide?.cta}
                  link={slide?.link}
                />
              );
            })}
        </Carousel>
      </div>
    </Wrapper>
  );
}

export default ActionBanner;
