import { anchor, formContentError, formContentSuccess, formDescription, formHeaderSpacing, formTitle, formTitleError, formTitleSuccess } from '@/utils/meta';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import Dealers from 'utils/repo/dealers';

class ContactForm implements ContentBlock {
  label = 'Contact Form';

  component = 'ContactForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    {
      name: 'allowedDealers',
      label: 'Allowed Dealers',
      help: 'List of allowed dialers to display, comma separated. Empty to allow all.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    anchor,
    formTitle,
    formDescription,
    formTitleSuccess,
    formContentSuccess,
    formTitleError,
    formContentError,
    formHeaderSpacing
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;
    const productId = extra?.context?.productId;

    const dealersByProductId = (await dataFetcher(await Dealers?.getAllDealersForForms(), { productId: productId }))?.data?.dealers || [];

    const trimmedAllowedDealers = (extra?.settings?.allowedDealers || '').trim();

    const allowedDealers = trimmedAllowedDealers ? trimmedAllowedDealers.split(',').map((dealer) => dealer.trim().toLocaleLowerCase()) : [];

    const dealers = allowedDealers.length > 0
    ? dealersByProductId.filter((dealer) => allowedDealers.includes(dealer.name.trim().toLocaleLowerCase()))
    : dealersByProductId;

    return {
      locale: String(locale).toLowerCase(),
      dealers: dealers ,
      title: extra?.settings?.title,
      description: extra?.settings?.description,
      titleSuccess: extra?.settings?.titleSuccess,
      contentSuccess: extra?.settings?.contentSuccess,
      titleError: extra?.settings?.titleError,
      contentError: extra?.settings?.contentError,
      headerSpacing: extra?.settings?.headerSpacing || '0',
    };
  }
}

export { ContactForm };
