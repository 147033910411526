import React from 'react';
import i18n from '../../../utils/i18n';
import { phoneMask, phoneMaskDdg, replaceUrl } from 'utils/validations';
import { capitalizeWords, formatClearPhoneNumber } from 'utils/functions';
import { urlHttpCheck } from '@on2-dev/on2-frontend-common/index.cjs';
import { IconShare, IconWhatsApp } from '../../__icons';
import Button from '../../__atoms/button';
import Title from '../../__atoms/title';

// Carregando o estilo sass para o componente
import css from './styles.module.scss';

/**
 * Molecule InfoDealer
 */

function InfoDealer(props) {
  const t = i18n.useTranslations('components.info-dealer');

  const {
    className = '',
    dealer = {},
    locale,
    urlWhats = t('url_whatsapp'),
    textWhats = t('text_whatsapp'),
    ...other
  } = props;

  const isTDB = locale?.toLowerCase() === 'pt-br';
  const isTASA = locale?.toLowerCase() === 'es-ar';

  function phoneMaskCondition(phone) {
    return phone.substring(0, 4) == '0800' ? phoneMaskDdg(phone) : phoneMask(phone);
  }

  function PhoneNumbersList() {
    const numbersArray = dealer?.phone.split(';');

    return numbersArray.map((number, index) => <p key={index}>{number}</p>);
  }

  return (
    <div className={`${css['molecule__info-dealer-container']} ${className}`} {...other}>
      <div className={css['info-dealer']}>
        <div className={css['info-dealer-header']}>
          <Title variant={4} level={3} color="primary">
            {capitalizeWords(dealer?.name)}
          </Title>
        </div>
        <div className={css['info-dealer-container']}>
          <div className={css['info-dealer-container__address']}>
            {isTDB && (
              <>
                <p>
                  {dealer?.address?.street && dealer?.address?.street.concat(',')}{' '}
                  {dealer?.address?.number && dealer?.address?.number.concat(',')}{' '}
                  {dealer?.address?.neighborhood && dealer?.address?.neighborhood.concat(',')}
                </p>
                <p>
                  {dealer?.address?.city && dealer?.address?.city.concat(',')}{' '}
                  {dealer?.address?.uf && dealer?.address?.uf.concat(',')} CEP{' '}
                  {dealer?.address?.zipcode}
                </p>
              </>
            )}
            {isTASA && (
              <>
                {dealer?.companyName && <p>Razón Social: {dealer?.companyName}</p>}
                {dealer?.idDealerTasa && <p>CUIT: {dealer?.idDealerTasa}</p>}
                <p>
                  {dealer?.address?.street && dealer?.address?.street}{' '}
                  {dealer?.address?.number && dealer?.address?.number.concat(' -')}{' '}
                  {dealer?.address?.city &&
                    dealer?.address?.city?.[0] &&
                    dealer?.address?.city?.[0]?.toUpperCase() +
                      dealer?.address?.city?.toLowerCase()?.substring(1)?.concat(',')}{' '}
                </p>
                <p>
                  {dealer?.address?.state &&
                    dealer?.address?.state?.[0] &&
                    dealer?.address?.state?.[0]?.toUpperCase() + dealer?.address?.state?.toLowerCase()?.substring(1)}
                </p>
              </>
            )}
            {dealer?.contactEmail && (
              <p className={css['email-address-container']}>
                <a
                  className={css['info-dealer-container__address__email']}
                  href={`mailto:${dealer?.contactEmail}`}
                  title={t('title_email')}
                >
                  {dealer?.contactEmail}
                </a>
              </p>
            )}
            {dealer?.site && (
              <a className={css['info-dealer-container__address__website']} target="_blank" href={urlHttpCheck(dealer?.site)}>
                <span>{replaceUrl(dealer?.site)}</span>
                <IconShare />
              </a>
            )}
            <div className={css['info-dealer-container__address__phones']}>
              {dealer?.phone &&
                ((isTDB && <p>{phoneMaskCondition(dealer?.phone)}</p>) || (isTASA && PhoneNumbersList()))}
              {dealer?.whatsapp && (
                <p className={css['info-dealer-container__address__cell-whatsap']}>
                  <span>
                    {isTDB && phoneMaskCondition(dealer?.whatsapp)}
                    {isTASA && dealer?.whatsapp}
                  </span>
                  <a href={urlWhats.concat(formatClearPhoneNumber(dealer?.whatsapp)).concat(textWhats)} aria-label="Whatsapp" title="Whatsapp">
                    <IconWhatsApp />
                  </a>
                </p>
              )}
            </div>
          </div>
          <div className={css['info-dealer-container__button']}>
            {isTDB && dealer?.whatsapp && (
              <div className={css['info-services__button-services']}>
                <Button link={urlWhats.concat(formatClearPhoneNumber(dealer?.whatsapp)).concat(textWhats)} className={css['button']}>
                  <span>{t('schedule_service')}</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoDealer;
