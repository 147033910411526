import React, { useState, useEffect } from 'react';

import AnchorLink from 'components/__atoms/anchor-link';
import Button from 'components/__atoms/button';
import FormCheckbox from 'components/__atoms/form-checkbox';
import FormInput from 'components/__atoms/form-input';
import FormSelect from 'components/__atoms/form-select';
import FormTextArea from 'components/__atoms/form-text-area';
import FormRadioButton from 'components/__atoms/form-radio-button';
import FormRadioGroup from 'components/__atoms/form-radio-group';
import Section from 'components/__atoms/section';
import Wrapper from 'components/__atoms/wrapper';
import ResponseSubmitForm from 'components/__molecules/response-submit-form';
import SectionHeader from 'components/__molecules/section-header';

import { captchaToken } from 'utils/captcha/token';
import getConfig from 'next/config';
import Script from 'next/script';
import i18n from 'utils/i18n';

import { isTextMask, sendRequest } from 'utils/forms';
import { empty } from 'utils/functions';
import { phoneMask } from 'utils/validations';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule Contact Form
 */

function ContactForm(props) {
  const t = i18n.useTranslations('components.contact-form');

  const {
    className = '',
    dealers = [],
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    title,
    description,
    headerSpacing,
    locale,
    ...other
  } = props;

  const isLocaleTdb = locale === 'pt-br';

  const validationSchemaConfig = {
    name: yup
      .string()
      .trim()
      .required(t('message_error_required_firstName'))
      .min(3, t('message_error_min_char_firstName')),
    surname: yup
      .string()
      .trim()
      .required(t('message_error_required_lastName'))
      .min(3, t('message_error_min_char_lastName')),
    email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
    telephone: yup
      .string()
      .required(t('message_error_required_telephone'))
      .min(14, t('message_error_min_char_telephone')),
    state: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_state'))
      .required(t('message_error_required_state')),
    city: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_city'))
      .required(t('message_error_required_city')),
    dealerId: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealership'))
      .required(t('message_error_required_dealership')),
    message: yup
    .string()
    .transform((value) => (value === null || value === undefined ? '' : value))
    .test('minLength', t('message_error_min_char_message'), (value) => {
      return !value || value.trim().length >= 3;
    }),
    isWhatsapp: yup.string().nullable(),
    hasToyotaVehicle: yup
      .string()
      .nullable()
      .transform((value) => (value === undefined || value === null ? null : value))
      .oneOf(['yes', 'no'], t('message_error_required_hasToyotaVehicle')),
    agreement: yup.boolean().oneOf([true], t('message_error_required_agreement')),
  };

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues : {
      agreement: false,
      name: "",
      surname: "",
      email: "",
      telephone: "",
      state: "placeholder",
      city: "placeholder",
      dealerId: "placeholder",
      message:"",
      hasToyotaVehicle: "",
      isWhatsapp: false
    }
  });

  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);

  const showResponseForm = isStatusComponent === 'success' || isStatusComponent === 'error';

  const watchedFields = watch([
    'name',
    'surname',
    'email',
    'telephone',
    'state',
    'city',
    'dealerId',
    'hasToyotaVehicle'
  ]);

  const watchedState = watch('state');
  const watchedCity = watch('city');

  function validateForm() {
    const isFormEmpty = watchedFields.some((value) => empty(value)
      || value === 'placeholder'
      ||  (typeof value === 'string'
      && (typeof value === 'string'
      && value?.trim() === '')));

    setIsDisable(isFormEmpty);
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function loadStates() {
    setListStates([...new Set(dealers.map((state) => state?.address?.state))].sort());
  }

  function loadCities(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() === selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered.map((city) => city.address.city.toUpperCase()))].sort();

    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    const filtered = dealers?.filter((dealer) => {
      return dealer?.address?.city.toUpperCase() === selectedCity?.toUpperCase();
    });
    setListDealers(filtered);
  }

  function onChangeState(e) {
    setValue('city', 'placeholder');
    setValue('dealerId', 'placeholder');
    setValue('state', e.currentTarget.value);
  }

  function onChangeCity(e) {
    setValue('dealerId', 'placeholder');
    setValue('city', e.currentTarget.value);
  }

  function onChangeDealers(e) {
    setValue('dealerId', e.currentTarget.value);
    trigger('dealerId');
  }

  const handleResponse = (success) => {
    if (success) {
      setIsStatusComponent('success');

      reset();
      return;
    }
    setIsStatusComponent('error');
    return;
  };

  function transformToBoolean(value) {
    return value.toLowerCase() === 'yes' || value.toLowerCase() === 'true' ;
  }


  const onSubmit = async (data, event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {

      const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
      if (!captchaPayload) {
        setIsStatusComponent('error');
        return false;
      }
      data.captchaPayload = captchaPayload;

      data.locale = locale;
      data.type = 'TSERVICE';
      data.hasToyotaVehicle = transformToBoolean(data.hasToyotaVehicle);
      data.isWhatsapp = transformToBoolean(data.isWhatsapp);

      delete data?.agreement;

      const response = await sendRequest('POST', 'api/contact', data);

      handleResponse(response.ok && response.status === 200);
    } catch (error) {
      handleResponse(false);
      console.error('Error while submitting the form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  const getTitleAndContent = (isStatusComponent) => {

    switch (isStatusComponent) {
      case 'success':
        return {
          title: titleSuccess ? titleSuccess : t('success_title'),
          content: contentSuccess ? contentSuccess : t('success_content'),
        };
      case 'error':
        return {
          title: titleError ? titleError : t('error_title'),
          content: contentError ? contentError : t('error_content'),
        };
      case 'default':
        return {
          title: title ? title : t('title_form'),
          content: description ? description : t('description_form'),
        };
      default:
        return {
          title: '',
          content: '',
        };
    }
  };

  useEffect(() => {
    loadCities(watchedState);
  }, [watchedState]);

  useEffect(() => {
    loadDealers(watchedCity);
  }, [watchedCity]);

  useEffect(() => {
    validateForm();
  }, [watchedFields]);

  useEffect(() => {
    loadStates();
  }, []);

  return (
    <Wrapper  spacing={headerSpacing} className={`${css['molecule__contact-form-container']} ${className}`} {...other}>
      <Section hasGrid fullScreen={false} >
        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
        ></Script>

        { showResponseForm &&
          <ResponseSubmitForm
            className={css['result_submit']}
            onBackForm={onBackForm}
            {...getTitleAndContent(isStatusComponent)}
            contentBackForm={t('content_back_form')}
            contentBackHome={t('content_back_home')}
          />
        }

        {isStatusComponent === 'default' && (
          <div className={css['contact-form-container-section']}>
            <SectionHeader
              title={getTitleAndContent(isStatusComponent).title}
              className={css['form-container_header']}
              description={{
                value: getTitleAndContent(isStatusComponent).content,
                textColor: 'gray-4'
              }}
            />
            <form onSubmit={handleSubmit(onSubmit, onError)} className={css['form-container']}>
              <div className={css['form-container-fields']}>
                <fieldset className={css['form-container_fieldset']}>
                  <FormInput
                    className={css['form-container__input']}
                    id="name"
                    label={t('label_first_name')}
                    placeholder={t('placeholder_first_name')}
                    dataRegister={register('name')}
                    dataErrors={errors['name']}
                    maxLength="50"
                    type="text"
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isTextMask(value);
                    }}
                  />
                  <FormInput
                    className={css['form-container__input']}
                    id="surname"
                    label={t('label_last_name')}
                    placeholder={t('placeholder_last_name')}
                    dataRegister={register('surname')}
                    dataErrors={errors['surname']}
                    maxLength="50"
                    type="text"
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isTextMask(value);
                    }}
                  />
                  <FormInput
                    className={css['form-container__input']}
                    id="email"
                    label={t('label-email')}
                    placeholder={t('placeholder_email')}
                    dataRegister={register('email')}
                    dataErrors={errors['email']}
                    type="email"
                    maxLength="50"
                  />
                </fieldset>

                <fieldset className={css['form-container_fieldset']}>
                  <FormInput
                    className={css['form-container__input']}
                    id="telephone"
                    label={t('label_telephone')}
                    placeholder={t('placeholder_telephone')}
                    dataRegister={register('telephone')}
                    dataErrors={errors['telephone']}
                    type="tel"
                    maxLength={isLocaleTdb ? '15' : '17'}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isLocaleTdb ? phoneMask(value) : value;
                    }}
                  />
                  <FormCheckbox
                    className={css['form-container__checkbox-phone']}
                    id="isWhatsapp"
                    dataRegister={register('isWhatsapp')}
                    dataErrors={errors['isWhatsapp']}
                  >
                    {t('label_isWhatsapp')}
                  </FormCheckbox>
                </fieldset>

                <fieldset className={css['form-container_fieldset']}>
                  <FormSelect
                    className={css['form-container__input']}
                    id="state"
                    label={t('label_state')}
                    placeholder={t('placeholder_state')}
                    onChange={onChangeState}
                    dataRegister={register('state')}
                    dataErrors={errors['state']}
                  >
                    {listStates.length > 0 &&
                      listStates?.map((state, index) => {
                        return (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={css['form-container__input']}
                    id="city"
                    label={t('label_city')}
                    placeholder={t('placeholder_city')}
                    onChange={onChangeCity}
                    dataRegister={register('city')}
                    dataErrors={errors['city']}
                  >
                    {watchedState &&
                      listCities?.map((city, index) => {
                        return (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={css['form-container__input']}
                    id="dealerId"
                    label={t('label_dealership')}
                    placeholder={t('placeholder_dealership')}
                    onChange={onChangeDealers}
                    dataRegister={register('dealerId')}
                    dataErrors={errors['dealerId']}
                  >
                    {watchedCity &&
                      listDealers?.map((dealer, index) => {
                        return (
                          <option key={index} value={dealer.id}>
                            {dealer.name.toUpperCase()}
                          </option>
                        );
                      })}
                  </FormSelect>
                </fieldset>


                <fieldset className={css['form-container_fieldset']}>
                  <FormRadioGroup
                    className={css['radio-group-label']}
                    label={t('label_hasToyotaVehicle')}
                    name="hasToyotaVehicle"
                    dataRegister={register('hasToyotaVehicle')}
                    dataErrors={errors['hasToyotaVehicle']}
                  >
                    <FormRadioButton className={css['radio-button']} id="radio-yes" value="yes">
                      {t('placeholder_radio_yes')}
                    </FormRadioButton>
                    <FormRadioButton className={css['radio-button']} id="radio-no" value="no">
                      {t('placeholder_radio_no')}
                    </FormRadioButton>
                  </FormRadioGroup>
                </fieldset>

                <fieldset className={css['form-container_fieldset']}>
                  <FormTextArea
                    rows="3"
                    id="message"
                    label={t('label_message')}
                    placeholder={t('placeholder_message')}
                    dataRegister={register('message')}
                    dataErrors={errors['message']}
                    className={css['form-container__text-area']}
                  />
                </fieldset>
              </div>
              <FormCheckbox
                className={css['form-container__checkbox-agreement']}
                id="agreement"
                dataRegister={register('agreement')}
                dataErrors={errors['agreement']}
                disabled={isDisable}
              >
                {t('label_agreement')}&nbsp;
                <AnchorLink
                  link={'https://media.toyota.com.br/18e5c50c-b52f-4988-a603-d146561744b6.pdf'}
                  hasArrow={false}
                  linkLabel={t('link_label_agreement')}
                  disabled={isDisable}
                />
              </FormCheckbox>

              <Button disabled={!formState?.isValid || isSubmitting } type="submit">
                {t('button_submit_form')}
              </Button>
            </form>
          </div>
        )}
      </Section>
    </Wrapper>
  );
}

export default ContactForm;
