import React from 'react';
import AnchorLink from '../../__atoms/anchor-link';
import Button from '../../__atoms/button';
import Title from '../../__atoms/title';
import Wrapper from '../../__atoms/wrapper';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule SuccessSubmitForm
 *
 * <!-- TODO: add a description here! -->
 */
function ResponseSubmitForm(props) {

  const {
    className = '',
    onBackForm,
    title,
    content,
    contentBackForm,
    contentBackHome,
    extraLink,
    extraLabelLink,
    spacingTop = '6',
    spacingBottom = '6',
    ...other
  } = props;

  return (
    <div className={`${css['molecule__response-submit-form-container']} ${className}`} {...other}>
      <Wrapper spacingTop={spacingTop} spacingBottom={spacingBottom}>
        <div className={css['response-container']}>
          <div className={css['response-container__header']}>
            {title && (
              <Title level={2} variant={4}>
                {title}
              </Title>
            )}
            {content && <p className={css['response-container__header__content']}>{content}</p>}
            {extraLabelLink && extraLink && (
              <AnchorLink
                className={css['anchor-extra-link']}
                linkLabel={extraLabelLink}
                link={extraLink}
                size="small"
              />
            )}
          </div>
          <div className={css['response-container__buttons']}>
            {contentBackForm && onBackForm && (
              <Button data-testid="back-button" color="ghost-2" onClick={onBackForm}>
                {contentBackForm}
              </Button>
            )}
            {contentBackHome && (
              <AnchorLink className={css['anchor-link']} linkLabel={contentBackHome} link="/" size="small" />
            )}
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default ResponseSubmitForm;
