import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import {
  spacingTop,
  spacingBottom,
  bgColor,
  centerContent,
  kindOfContent,
  anchor,
  showTitle
} from 'utils/meta';

class SectionCard implements ContentBlock {
  label = 'Card List';

  component = 'SectionCard';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    showTitle,
    {
      name: 'isHorizontal',
      label: 'Landscape?',
      help: 'Display the Card in landscape mode',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    },
    centerContent,
    {
      name: 'removeCarousel',
      label: 'Remove the Carousel?',
      help: 'Remove carousel from card list',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    },
    {
      name: 'variant',
      label: 'The number of collumns the layout will be show',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'type-2',
        'type-3'
      ]
    },
    spacingTop,
    spacingBottom,
    bgColor,
    {
      name: 'callToActionLabel',
      label: 'Button title',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActionTarget',
      label: 'Button target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'buttonColor',
      label: 'Select header button color',
      help: 'Set the header button color',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null,
      options: [
        {
          key: 'light',
          value: 'light',
          text: 'White'
        },
        {
          key: 'light',
          value: 'dark',
          text: 'Dark'
        },
        {
          key: 'ghost',
          value: 'ghost',
          text: 'Transparent with white font'
        },
        {
          key: 'ghost-2',
          value: 'ghost-2',
          text: 'Transparent with black font'
        },
        {
          key: 'ghost-3',
          value: 'ghost-3',
          text: 'Grey with white font'
        },
        {
          key: 'ghost-4',
          value: 'ghost-4',
          text: 'Light blue with white font'
        },
        {
          key: 'ghost-5',
          value: 'ghost-5',
          text: 'Dark blue with white font'
        }
      ]
    },
    kindOfContent,
    anchor
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'Set the card title',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The content of the card',
      type: 'string',
      control: 'textarea',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Image',
      help: 'Set an image to the card',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].label',
      label: 'Link title',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].target',
      label: 'Link target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {
      title: extra?.section?.name,
      description: extra?.section?.description,
      settings: extra?.settings,
      buttonColor: extra?.settings?.buttonColor,
      bgColor: extra?.settings?.bgColor,
      centerContent: extra?.settings?.centerContent,
      spacingTop: extra?.settings?.spacingTop,
      spacingBottom: extra?.settings?.spacingBottom,
      callToAction: extra?.settings?.callToActionLabel
        ? {
            label: extra?.settings?.callToActionLabel,
            target: extra?.settings?.callToActionTarget
          }
        : null,
      cardsData: contents.map((content) => {
        if (!content?.title) {
          throw Error("Content 'title' are required");
        }

        return {
          title: content?.title.trim().length > 0 ? content?.title?.trim() : undefined,
          content: content?.description,
          media: content?.media
            ? {
                type: content?.media?.mediaType?.title,
                url: content?.media?.url,
                alt: content?.media?.description
              }
            : null,
          links: content?.callToActions.map((data) => {
              return {
                link: data.target,
                linkLabel: data.label
              };
          })
        };
      })
    };
  }
}

export { SectionCard };
